<div class="container">
  <figure class="banner-image-mobile">
    <img src="assets/cq/cq-image-m.jpg" alt="Club Quarters Hotels logo">
  </figure>
  <section class="subscription-section">
    <form (submit)="submitSubscriptionSettings($event)">
      <header class="subscription-info">
        Subscription preferences for {{ visibleEmail }}
      </header>
      <main class="subscription-management">
        <div class="management-tile title">
          <h3>
            <img src="assets/icons8-message.png">
            <span class="title-text">Manage My Subscriptions</span>
          </h3>
          <p>
            We appreciate your interest in Club Quarters Hotels and would love to keep
            in touch.
          </p>
          <p>
            Please let us know what communications you would like to receive.
          </p>
        </div>
        <div class="management-tile subscribe-all">
          <label class="input-label">
            <radiobutton
              inputName="subscription"
              [(model)]="isAllSelected"
              (onChange)="onAllSelected()"
            ></radiobutton>
            <span class="radio-text">Subscribe me to all available</span>
          </label>
        </div>
        <div class="management-tile subscribe-partial">
          <p class="subscription-options-title">I'm only interested in communication related to:</p>
          <input
            [checked]="isPartiallySelected"
            type="radio" 
            name="subscription" style="display: none;"
          >
          <ul class="subscription-options">
            <li *ngFor="let option of subscriptionOptions" class="option">
              <label class="input-label">
                <div class="checkbox-container">
                  <checkbox [(model)]="option.checked" (change)="onSubscriptionOptionChange()"></checkbox>
                </div>
                <span>{{ option.displayText }}</span>
              </label>
            </li>
          </ul>
        </div>
        <div class="management-tile unsubscribe-all">
          <label class="input-label">
            <radiobutton
              inputName="subscription"
              [(model)]="isAllUnselected"
              (onChange)="onAllUnselected()"
            ></radiobutton>
            <span class="radio-text">Unsubscribe me to all available</span>
          </label>
        </div>
      </main>
      <footer class="subscription-footer">
        <button class="button submit-button" type="submit" [disabled]="isFormSubmitted">
          <img class="loading-icon" src="assets/baseline-cached-24px.svg">
          update
        </button>
      </footer>
    </form>
  </section>
  <figure class="banner-image">
    <img src="assets/cq/cq-image.jpg" alt="CQ image">
  </figure>
</div>