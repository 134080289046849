import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: [ 'checkbox.component.scss' ]
})
export class CheckboxComponent implements OnInit {

  @Input('inputId')
  public id: string;

  @Input()
  public model: boolean = false;

  @Output()
  private modelChange = new EventEmitter<boolean>();

  @Output()
  private change = new EventEmitter<boolean>();

  public ngOnInit(): void {
    //console.log(this.model);
  }

  public onChecked(event: Event): void {
    this.model = (<HTMLInputElement>event.target).checked;
    this.modelChange.emit(this.model);
    this.change.emit(this.model);
  }

}
