import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';
import { SubscriptionResultComponent } from './subscription-result/subscription-result.component';

@NgModule({
  declarations: [
    AppComponent,
    ManageSubscriptionComponent,
    SubscriptionResultComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FormsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
