import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionOption } from '../models';
import { SubscriptionService } from '../services/subscription.service';

interface SubscriptionOptionInput {
  checked: boolean;
  value: SubscriptionOption;
  displayText: string;
}

const allSubscriptionOptions: {
  [key: string]: string;
} = {
  [SubscriptionOption.DirectEmailConsent]: 'Offers',
  [SubscriptionOption.DirectMailConsent]: 'Direct Mail',
  [SubscriptionOption.DirectSmsConsent]:  'SMS/Text Marketing',
  [SubscriptionOption.LoyaltyProgramConsent]: 'Club Quarters Rewards',
  [SubscriptionOption.MarketingResearchConsent]: 'Surveys',
};

@Component({
  selector: 'app-manage-subscription',
  templateUrl: 'manage-subscription.component.html',
  styleUrls: [ 'manage-subscription.component.scss' ]
})
export class ManageSubscriptionComponent implements OnInit{
  private payload: string;

  public visibleEmail: string = '';
  public isAllSelected: boolean = false;
  public isAllUnselected: boolean = false;
  public isPartiallySelected: boolean = false;
  public isFormSubmitted: boolean = false;

  public subscriptionOptions: SubscriptionOptionInput[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  public ngOnInit(): void {    
    this.activatedRoute.queryParams.subscribe(params => {
      this.payload = params['payload'] ;
      if (!this.payload) {
        this.redirectToWebsite();
      }
      this.subscriptionService.getSubscriptionConsents(this.payload).then(options => {
        if (!options) {
          this.redirectToWebsite();
        }
        this.visibleEmail = options.email;
        this.subscriptionOptions = options.availableOptions
          .filter(opt => !!allSubscriptionOptions[opt])
          .map((opt): SubscriptionOptionInput => ({
            value: opt,
            displayText: allSubscriptionOptions[opt],
            checked: false
          }))
          .sort((o1, o2) => o1.displayText < o2.displayText ? -1 : 1);
        options.selectedOptions.forEach(
          option => this.subscriptionOptions.find(op => op.value === option).checked = true
        );
        this.onSubscriptionOptionChange();
      });
    });
  }

  public submitSubscriptionSettings(event: Event): void {
    event.preventDefault();
    this.isFormSubmitted = true;
    const selectedOptions = this.subscriptionOptions.filter(opt => opt.checked)
      .map(opt => opt.value);
    const subscribtionResult = this.subscriptionOptions.reduce((acc, cur) => acc && !cur.checked, true) ?
      'unsubscribe' : 'subscribe';
    this.subscriptionService.setSubscriptionConsents(selectedOptions)
      .then(resp => this.router.navigateByUrl(`/subscription-result/${subscribtionResult}`))
      .catch(err => this.isFormSubmitted = false);
  }

  public onSubscriptionOptionChange(): void {
    this.isAllSelected = this.subscriptionOptions
      .map(option => option.checked)
      .reduce((acc, cur) => acc && cur, true);
    
    this.isAllUnselected = this.subscriptionOptions
      .map(option => !option.checked)
      .reduce((acc, cur) => acc && cur, true);

    this.isPartiallySelected = !this.isAllSelected && !this.isAllUnselected;
  }

  public onAllSelected(): void {
    this.isAllUnselected = false;
    this.changeSubscriptions(true);
  }

  public onAllUnselected(): void {
    this.isAllSelected = false;
    this.changeSubscriptions(false);
  }

  private changeSubscriptions(value: boolean): void {
    this.subscriptionOptions.forEach(option => option.checked = value);
  }

  private redirectToWebsite(): void {
    window.location.href = 'https://clubquartershotels.com';
  }

}
