<header>
  <figure class="hotel-logo">
    <a href="https://clubquartershotels.com">
      <img src="assets/cq/hotel_logo.jpg" alt="Hotel logo">
    </a>
  </figure>
</header>
<main class="content">
  <router-outlet></router-outlet>
</main>
<footer class="footer">
  <div class="links">
    <a target="_blank" href="https://clubquartershotels.com/terms-conditions">Terms and Conditions</a> |
    <a target="_blank" href="https://clubquartershotels.com/privacy-policy">Privacy Policy</a>
  </div>
</footer>