import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'radiobutton',
  templateUrl: 'radiobutton.component.html',
  styleUrls: [ 'radiobutton.component.scss' ]
})
export class RadiobuttonComponent {

  @Input()
  public inputName: string;

  @Input()
  public model: boolean = false;

  @Output()
  private modelChange = new EventEmitter<boolean>();

  @Output('onChange')
  private change = new EventEmitter<boolean>();

  public onInputChange(event: Event): void {
    const elem = event.target as HTMLInputElement;
    this.model = elem.checked;
    this.modelChange.emit(this.model);
    this.change.emit(this.model);
  }

}