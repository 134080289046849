<div class="input-container">
  <input
    type="radio"
    [attr.name]="inputName"
    [checked]="model"
    (change)="onInputChange($event)"
  >
  <div class="input-style">
    <div class="inner"></div>
  </div>
</div>
