import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';

@NgModule({
  imports: [
    FormsModule
  ],
  declarations: [
    CheckboxComponent,
    RadiobuttonComponent,
  ],
  exports: [
    CheckboxComponent,
    RadiobuttonComponent,
  ]
})
export class SharedModule {}
