import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-subscription-result',
  templateUrl: 'subscription-result.component.html',
  styleUrls: [ 'subscription-result.component.scss' ]
})
export class SubscriptionResultComponent implements OnInit {
  private resultMessages = {
    subscribe: 'Your subscription preferences have been updated.',
    unsubscribe: 'You have successfully unsubscribed from all communications.'
  };
  public resultMessage: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}

  public ngOnInit(): void {
    if (!this.subscriptionService.doesPayloadExist) {
      window.location.href = 'https://www.clubquartershotels.com/';
    }
    this.activatedRoute.params.subscribe(params => {
      this.resultMessage = this.resultMessages[params['result']];
    });
  }
}
