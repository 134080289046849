import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubscriptionOption, Consents } from '../models';
import { BaseResponse } from '../models/base-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private baseUrl: string = environment.baseurl;
  private operatedPayload: string;

  public get doesPayloadExist(): boolean {
    return !!this.operatedPayload;
  }

  constructor(
    private http: HttpClient
  ) {}

  public getSubscriptionConsents(payload: string): Promise<{
    availableOptions: SubscriptionOption[];
    selectedOptions: SubscriptionOption[];
    email: string;
  }> {
    this.operatedPayload = payload;
    // ANGULAR APPLIES WEIRD TRANSFORMATIONS TO URL PARAMETERS. THIS IS WHY FETCH IS USED INSTEAD OF ANGULAR'S HTTP CLIENT.
    return window.fetch(`${this.baseUrl}/api/subscription/consents?payload=${encodeURIComponent(payload)}`)
      .then(resp => resp.json())
      .then((resp: BaseResponse<{ Consents: Consents, Email: string }>) => {
        if (resp.Success) {
          return {
            availableOptions: this.availableSubscriptionOptions(resp.Data.Consents),
            selectedOptions: this.mapConsentsToSubscriptionOptions(resp.Data.Consents),
            email: resp.Data.Email
          };
        }
        return null;
      });
  }

  public setSubscriptionConsents(options: SubscriptionOption[]): Promise<BaseResponse<void>> {
    return this.http.post<BaseResponse<void>>(`${this.baseUrl}/api/subscription/consents`, {
      Payload: this.operatedPayload, Consents: this.mapSubscriptionOptionsToConsents(options)
    }).toPromise();
  }

  private availableSubscriptionOptions(consents: Consents): SubscriptionOption[] {
    return Object.keys(consents).map(consent => {
      switch(consent) {
        case 'DirectEmailConsent':
          return SubscriptionOption.DirectEmailConsent;
        case 'DirectMailConsent':
          return SubscriptionOption.DirectMailConsent;
        case 'DirectSmsConsent':
          return SubscriptionOption.DirectSmsConsent;
        case 'LoyaltyProgramConsent':
          return SubscriptionOption.LoyaltyProgramConsent;
        case 'MarketingResearchConsent':
          return SubscriptionOption.MarketingResearchConsent;
      }
    });
  }

  private mapConsentsToSubscriptionOptions(consents: Consents): SubscriptionOption[] {
    const options: SubscriptionOption[] = [];
    if (consents.DirectEmailConsent) {
      options.push(SubscriptionOption.DirectEmailConsent);
    }
    if (consents.DirectMailConsent) {
      options.push(SubscriptionOption.DirectMailConsent);
    }
    if (consents.MarketingResearchConsent) {
      options.push(SubscriptionOption.MarketingResearchConsent);
    }
    if (consents.DirectSmsConsent) {
      options.push(SubscriptionOption.DirectSmsConsent);
    }
    if (consents.LoyaltyProgramConsent) {
      options.push(SubscriptionOption.LoyaltyProgramConsent  );
    }
    return options;
  }

  private mapSubscriptionOptionsToConsents(options: SubscriptionOption[]): Consents {
    const consents: Consents = {
    DirectCallConsent:false,
    DirectEmailConsent:false,
    DirectMailConsent:false,
    DirectSmsConsent:false,
    LoyaltyProgramConsent:false,
    MarketingResearchConsent:false,
    ProfileDataConsent:false,
    };
    return options.reduce((acc, cur) => {
      switch(cur) {
        case SubscriptionOption.DirectEmailConsent:
          acc.DirectEmailConsent = true;
          break;
        case SubscriptionOption.DirectMailConsent:
          acc.DirectMailConsent = true;
          break;
        case SubscriptionOption.DirectSmsConsent:
          acc.DirectSmsConsent = true;
          break;
        case SubscriptionOption.LoyaltyProgramConsent:
            acc.LoyaltyProgramConsent = true;
            break;
        case SubscriptionOption.MarketingResearchConsent:
              acc.MarketingResearchConsent = true;
              break;
      }
      return acc;
    }, consents);
  }
}
